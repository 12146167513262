import React from "react";
import PlaceholderLine from "app.components/Placeholder/PlaceholderLine";

const NavigationBarPlaceholder = () => {
  return (
    <div className="c_navigation-bar container flex h-12 max-w-none items-center bg-blue-100 px-4 transition-all">
      <PlaceholderLine width="w-48" color="bg-zinc-300" />
    </div>
  );
};

NavigationBarPlaceholder.displayName = "NavigationBarPlaceholder";

export default NavigationBarPlaceholder;

import React from "react";
import { Route, Routes, Outlet } from "react-router-dom";

import { useTranslation } from "react-i18next";
import PortfolioPlaceholder from "./PortfolioPlaceholder";
import PortfolioNewPlaceholder from "./PortfolioNewPlaceholder";
import WalletPlaceholderRouter from "./WalletPlaceholderRouter";
import TimelinePlaceholder from "./TimelinePlaceholder";
import AccountPlaceholder from "./AccountPlaceholder";
import GenericPlaceholder from "./GenericPlaceholder";

const PlaceholderRouter = () => {
  const { t } = useTranslation();
  return (
    <>
      <p className="sr-only">{t("common.loading_withEllipsis")}</p>
      <Routes>
        <Route path="/" element={<Outlet />}>
          <Route index element={<TimelinePlaceholder />} />
          <Route path="smart" element={<GenericPlaceholder />} />
          <Route path="forms" element={<GenericPlaceholder />} />
          <Route path="reports" element={<GenericPlaceholder />} />
          <Route path="archived" element={<GenericPlaceholder />} />
          <Route path="settings">
            <Route path="*" element={<GenericPlaceholder />} />
          </Route>
          <Route path="new">
            <Route path="*" element={<PortfolioNewPlaceholder />} />
          </Route>
          <Route path="add-ledger">
            <Route path="*" element={<GenericPlaceholder />} />
          </Route>
          <Route path="ledgers" element={<PortfolioPlaceholder />} />
          <Route path="ledger/:ledgerId">
            <Route path="*" element={<WalletPlaceholderRouter />} />
          </Route>
          <Route
            path="smart/:smartWalletGUID"
            element={<WalletPlaceholderRouter />}
          />
          <Route path="account">
            <Route path="*" element={<AccountPlaceholder />} />
          </Route>
        </Route>
      </Routes>
    </>
  );
};

PlaceholderRouter.displayName = "PlaceholderRouter";

export default PlaceholderRouter;

/*



<Route
path={`${match.url}/`}
render={(ownProps) => {
  return ;
}}
/>
</Routes>

*/

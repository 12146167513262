import { makeActionCreator } from "app.actions/helpers";
// LEDGER
export const FILES_BEGIN = "FILES_BEGIN";
export const FILES_REQUEST = "FILES_REQUEST";
export const FILES_RECEIVE = "FILES_RECEIVE";
export const FILES_ERROR = "FILES_ERROR";

export const fetchFiles = makeActionCreator(FILES_BEGIN, "oneTime");

export const requestFiles = makeActionCreator(FILES_REQUEST, "nextStatus");

export const receiveFiles = makeActionCreator(
  FILES_RECEIVE,
  "documents",
  "documentsComplete",
  "metadata",
  "nextStatus"
);

export const filesError = makeActionCreator(
  FILES_ERROR,
  "response",
  "nextStatus"
);

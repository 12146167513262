import React from "react";
import Input from "app.components/Util/Form/Input";
import SvgLoader from "app.components/Util/SvgLoader";
import { useTranslation } from "react-i18next";

const SearchInput = ({
  value,
  size,
  label,
  placeholder,
  changeCallback,
  clearCallback,
  fullWidth,
  requireActivation = false,
  activationCallback,
  ...htmlAttributes
}) => {
  const { t } = useTranslation();

  const containerClass = [
    "relative transition-all",
    fullWidth ? "w-full" : "",
  ].join(" ");

  return (
    <div className={containerClass}>
      {label ? <span className="sr-only">{label}</span> : null}
      <SvgLoader
        id="Search"
        className="absolute left-2 top-1/2 -mt-2 h-4 w-4 stroke-zinc-500"
      />
      <Input
        type="text"
        size={size}
        placeholder={placeholder}
        value={value}
        className={[fullWidth ? "w-full" : "", "px-7"].join(" ")}
        onChange={(e) => {
          e.preventDefault();
          changeCallback(e.target.value);
        }}
        {...htmlAttributes}
      />
      {requireActivation ? (
        <div className="absolute bottom-0 right-2 top-0 my-0.5 flex flex-row-reverse items-center gap-2 bg-white pl-0.5">
          {requireActivation && value ? (
            <button
              type="submit"
              className="hover:group rounded border border-zinc-500 px-1.5 py-0.5 hover:border-blue-500 hover:bg-blue-100"
              onClick={(e) => {
                e.preventDefault();
                activationCallback();
              }}
            >
              <span className="sr-only">{t("common.search")}</span>
              <SvgLoader
                id="ArrowDownLeft"
                className="h-3.5 w-3.5 fill-zinc-600 group-hover:fill-blue-600"
              />
            </button>
          ) : null}
          {requireActivation && value === "" ? (
            <button
              type="submit"
              className=""
              onClick={(e) => {
                e.preventDefault();
                activationCallback();
              }}
            >
              <span className="sr-only">{t("common.search")}</span>
            </button>
          ) : null}
          {value ? (
            <button
              type="button"
              className="h-4 w-4"
              onClick={(e) => {
                e.preventDefault();
                clearCallback();
              }}
            >
              <span className="sr-only">{t("common.clear")}</span>
              <SvgLoader id="Close" className="h-4 w-4 stroke-zinc-500" />
            </button>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

SearchInput.displayName = "SearchInput";

export default SearchInput;

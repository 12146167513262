import api from "app.api/clients/exchange";
/**
 * Submits a request to label an address
 * @async
 * @function
 * @param {string} address - the address to label
 * @param {string} label - The label for the address
 * @returns {object}  response - The Response or Error message
 */
export const saveAddressLabel = (address, label) => {
  const data = { address, label };

  return api.post(`/tag/address/create`, {
    data,
    authenticated: true,
  });
};

/**
 * Submits a request to get a list of a users saved address labels
 * @async
 * @function
 * @returns {object}  response - The Response or Error message
 */
export const getAddressLabels = () => {
  return api.get(`/tag/labels`, {
    authenticated: true,
  });
};

import { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authorizeUser } from "app.reducers/user";

const AuthenticateUser = () => {
  const [mounted, setMounted] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const reduxDispatch = useDispatch();

  const ticket = searchParams.get("ticket");
  const redirect = searchParams.get("redirect");

  useEffect(() => {
    if (mounted === false) {
      // no ticket
      if (typeof ticket === "undefined") {
        navigate("/", { replace: true });
        return;
      }

      // if a user is some how redirected to this route send them to home
      const redirectTo =
        redirect && redirect.indexOf("authenticate") >= 0 ? null : redirect;
      reduxDispatch(authorizeUser({ ticket, redirectTo }));
    }
    setMounted(true);
  }, [mounted, ticket, redirect, navigate, reduxDispatch]);

  return null;
};
AuthenticateUser.displayName = "AuthenticateUser";
export default AuthenticateUser;

import { makeActionCreator } from "app.actions/helpers";
// LEDGER
export const SHARE_FILE_BEGIN = "SHARE_FILE_BEGIN";
export const SHARE_FILE_REQUEST = "SHARE_FILE_REQUEST";
export const SHARE_FILE_RECEIVE = "SHARE_FILE_RECEIVE";
export const SHARE_FILE_ERROR = "SHARE_FILE_ERROR";

export const shareFile = makeActionCreator(SHARE_FILE_BEGIN, "documentGUID");

export const requestShareFile = makeActionCreator(
  SHARE_FILE_REQUEST,
  "documentGUID"
);

export const receiveShareFile = makeActionCreator(
  SHARE_FILE_RECEIVE,
  "documentGUID"
);

export const shareFileError = makeActionCreator(SHARE_FILE_ERROR, "response");

import { takeEvery } from "redux-saga/effects";
import * as filesActions from "app.actions/files";

import fetchFiles from "./fetchFiles";
import generateFile from "./generateFile";
import downloadFile from "./downloadFile";
import getShareLink from "./getShareLink";

// WATCHERS
const filesSagas = [
  takeEvery(filesActions.FILES_BEGIN, fetchFiles),
  takeEvery(filesActions.GENERATE_FILE_BEGIN, generateFile),
  takeEvery(filesActions.DOWNLOAD_FILE_BEGIN, downloadFile),
  takeEvery(filesActions.SHARE_FILE_BEGIN, getShareLink),
];

// export all watcher sagas as an array to be composed in the top level
// root saga
export default filesSagas;

import React from "react";
import { Provider, ReactReduxContext } from "react-redux";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
} from "react-router-dom";

import AuthenticateUser from "app.components/AuthenticateUser";
import AuthenticatedApp from "app.components/AuthenticatedApp";
import EnrollUser from "app.components/EnrollUser";
import Inspector from "app.components/Inspector";
import ForgotPassword from "app.components/Account/ForgotPassword";
import ResetPassword from "app.components/Account/ResetPassword";

import RouterErrorBoundary from "app.components/Util/RouterErrorBoundary";

import { USER_SELF_ENROLL } from "app.constants";

// the root component is a stateless functional react component
// (meaning it isn't created as a component class, but just a basic
// function which takes in props as arguments. this component doesn't have the
// typical react lifecycle functions. These are pure
// functions which can possibly be further optimized automatically in React
// because the library will be able to make certain assumptions

// The Root component takes in the Redux store as its lone prop.
const Root = (
  { store } // Provider is a special Redux component that bridges React to Redux.
) => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" errorElement={<RouterErrorBoundary />}>
        <Route path="*" element={<Outlet />}>
          {USER_SELF_ENROLL ? (
            <Route path="enroll" errorElement={<RouterErrorBoundary />}>
              <Route path="*" element={<EnrollUser />} />
            </Route>
          ) : (
            <Route path="enroll">
              <Route path="*" element={<Navigate to="/" />} />
            </Route>
          )}
          <Route path="forgotpassword" errorElement={<RouterErrorBoundary />}>
            <Route path="*" element={<ForgotPassword />} />
          </Route>
          <Route path="resetpassword" errorElement={<RouterErrorBoundary />}>
            <Route path="*" element={<ResetPassword />} />
          </Route>
          <Route
            path="authenticate"
            errorElement={<RouterErrorBoundary />}
            element={<AuthenticateUser />}
          />
          <Route
            path="inspector"
            errorElement={<RouterErrorBoundary />}
            element={<Inspector />}
          />
          <Route
            index
            path="*"
            errorElement={<RouterErrorBoundary />}
            element={<AuthenticatedApp />}
          />
        </Route>
      </Route>
    ),
    {
      future: {
        v7_relativeSplatPath: true,
        v7_fetcherPersist: true,
        v7_normalizeFormMethod: true,
        v7_partialHydration: true,
        v7_skipActionErrorRevalidation: true,
      },
    }
  );

  return (
    <React.StrictMode>
      <Provider store={store} context={ReactReduxContext}>
        <RouterProvider router={router} future={{ v7_startTransition: true }} />
      </Provider>
    </React.StrictMode>
  );
};

Root.displayName = "Root";

export default Root;

import { makeActionCreator } from "app.actions/helpers";
// LEDGER
export const DOWNLOAD_FILE_BEGIN = "DOWNLOAD_FILE_BEGIN";
export const DOWNLOAD_FILE_REQUEST = "DOWNLOAD_FILE_REQUEST";
export const DOWNLOAD_FILE_RECEIVE = "DOWNLOAD_FILE_RECEIVE";
export const DOWNLOAD_FILE_ERROR = "DOWNLOAD_FILE_ERROR";

export const downloadFile = makeActionCreator(
  DOWNLOAD_FILE_BEGIN,
  "documentGUID"
);

export const requestDownloadFile = makeActionCreator(
  DOWNLOAD_FILE_REQUEST,
  "documentGUID"
);

export const receiveDownloadFile = makeActionCreator(
  DOWNLOAD_FILE_RECEIVE,
  "documentGUID"
);

export const downloadFileError = makeActionCreator(
  DOWNLOAD_FILE_ERROR,
  "response"
);

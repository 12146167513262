import React from "react";
import { useTranslation } from "react-i18next";

const ErrorMessage = ({ type, code, message }) => {
  const { t } = useTranslation();

  let msg = t("errors.messages.unknownErrorEncountered");

  switch (message) {
    case "invalid_request":
      msg = message;
      break;
    case "email_already_exists":
      msg = t("errors.messages.emailAlreadyExists");
      break;
    default:
      msg = message || msg;
      break;
  }

  if (typeof type === "undefined" && typeof code === "undefined") {
    return null;
  }

  return (
    <div key={type || code} className="overflow-hidden rounded bg-red-50 p-2">
      <p className="text-sm text-red-700">{msg}</p>
    </div>
  );
};

ErrorMessage.displayName = "ErrorMessage";

export default ErrorMessage;

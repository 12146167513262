import React from "react";
import PlaceholderLine from "app.components/Placeholder/PlaceholderLine";
import TableList from "app.components/TableList/TableList";
import TableListHeader from "app.components/TableList/TableListHeader";
import TableListCell from "app.components/TableList/TableListCell";
import SvgLoader from "app.components/Util/SvgLoader";

const TableRow = () => (
  <tr>
    <TableListCell height="h-16">
      <SvgLoader id="Star" className="h-4 w-4 fill-zinc-200" />
    </TableListCell>
    <TableListCell left custom="w-60">
      <PlaceholderLine width="w-full" innerClassName="mb-1" />
    </TableListCell>
    <TableListCell left custom="w-60">
      <PlaceholderLine width="w-full" innerClassName="mb-1" />
    </TableListCell>
    <TableListCell left>
      <PlaceholderLine width="w-full" innerClassName="mb-1" />
    </TableListCell>

    <TableListCell left>
      <PlaceholderLine width="w-full" innerClassName="mb-1" />
    </TableListCell>

    <TableListCell right>
      <PlaceholderLine width="w-full" innerClassName="mb-1" />
    </TableListCell>
    <TableListCell custom="w-8" />
  </tr>
);

const TableBody = () => (
  <tbody>
    <TableRow />
    <TableRow />
    <TableRow />
  </tbody>
);

const TableHead = () => (
  <thead>
    <tr>
      <TableListHeader custom="w-4 pr-0" />
      <TableListHeader left custom="w-60">
        <PlaceholderLine width="w-full" innerClassName="mb-1" />
      </TableListHeader>
      <TableListHeader left custom="w-60">
        <PlaceholderLine width="w-full" innerClassName="mb-1" />
      </TableListHeader>
      <TableListHeader left>
        <PlaceholderLine width="w-full" innerClassName="mb-1" />
      </TableListHeader>

      <TableListHeader left>
        <PlaceholderLine width="w-full" innerClassName="mb-1" />
      </TableListHeader>

      <TableListHeader right>
        <PlaceholderLine width="w-full" innerClassName="mb-1" />
      </TableListHeader>
      <TableListHeader custom="w-8" />
    </tr>
  </thead>
);

const GroupHeader = () => (
  <div className="flex h-16 items-center justify-between px-2.5">
    <div>
      <PlaceholderLine width="w-7" height="h-7" innerClassName="mr-2.5" />
      <PlaceholderLine width="w-48" />
    </div>
    <PlaceholderLine width="w-24" />
  </div>
);

const PorfolioCardPlaceholder = () => (
  <li className="mb-2.5 rounded bg-white shadow last:mb-0">
    <GroupHeader />
    <TableList zebra>
      <TableHead />
      <TableBody />
    </TableList>
  </li>
);

const PortfolioPlaceholder = () => (
  <div className="c_portfolio_placeholder mx-4 my-5 rounded-sm bg-white shadow-lg">
    <div className="px-4 pb-4">
      <div className="pt-2.5">
        <ul className="flex h-12 items-center">
          <li className="flex-initial px-4">
            <PlaceholderLine width="w-20" />
          </li>
          <li className="flex-initial px-4">
            <PlaceholderLine width="w-20" />
          </li>
          <li className="flex-initial px-4">
            <PlaceholderLine width="w-32" />
          </li>
          <li className="flex-initial px-4">
            <PlaceholderLine width="w-32" />
          </li>
          <li className="flex-initial px-4">
            <PlaceholderLine width="w-52" />
          </li>
        </ul>
      </div>

      <div className="border border-zinc-200">
        <div className="my-2.5 flex h-8 items-center justify-end gap-2 px-2.5">
          <PlaceholderLine width="w-24" />
          <PlaceholderLine width="w-24" />
        </div>
        <div className="flex justify-between p-2.5">
          <div className="flex gap-2">
            <PlaceholderLine width="w-64" />
            <PlaceholderLine width="w-32" />
            <PlaceholderLine width="w-32" />
            <PlaceholderLine width="w-32" />
          </div>
          <PlaceholderLine width="w-52" />
        </div>

        <ul className="p-2.5 pt-5">
          <PorfolioCardPlaceholder />
          <PorfolioCardPlaceholder />
          <PorfolioCardPlaceholder />
        </ul>
      </div>
    </div>
  </div>
);

PortfolioPlaceholder.displayName = "PortfolioPlaceholder";

export default PortfolioPlaceholder;

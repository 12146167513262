/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { Link, useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { removeFlashMessage } from "app.reducers/ui";
import { Trans, useTranslation } from "react-i18next";

import {
  ROLL_FORWARD_EXAMPLE,
  getDownloadDescriptionShort,
} from "app.constants";

import Button from "app.components/Util/Button";
import SvgLoader from "app.components/Util/SvgLoader";

const DOCUMENTS_ROUTES_DEFAULT = {
  link: "/portfolio/reports",
  linkMessage: "messages.reportDownloadReady",
  errorMessage: "messages.reportDownloadError",
};

const DOCUMENTS_ROUTES = [
  {
    type: ROLL_FORWARD_EXAMPLE,
    link: "/portfolio/settings",
    linkMessage: "messages.rollForwardExampleDownloadReady",
    errorMessage: "messages.rollForwardExampleError",
  },
];

export const MESSAGE_TYPES = {
  DOWNLOAD_READY: "DOWNLOAD_READY",
  DOWNLOAD_ERROR: "DOWNLOAD_ERROR",
  LEDGER_READY: "LEDGER_READY",
};

export const getDownloadReadyMessage = (document, messageId) => {
  function DownloadReadyMessage({ doc, id }) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const reduxDispatch = useDispatch();

    const { link, linkMessage } =
      DOCUMENTS_ROUTES.find(({ type }) => type === doc.fileType) ||
      DOCUMENTS_ROUTES_DEFAULT;

    const isShare = doc.shareLink === true;

    const descr = isShare ? "messages.reportDocumentReady" : linkMessage;

    return (
      <>
        <h4 className="text-md mb-1 font-bold leading-none text-black/90">
          {isShare ? t("messages.documentReady") : t("messages.downloadReady")}
        </h4>
        <p className="text-sm text-black/80">
          <Trans i18nKey={descr}>
            <strong>
              {{
                name: getDownloadDescriptionShort(doc.fileType),
              }}
            </strong>
            <Button
              buttonType="link"
              customClasses="py-0"
              onClick={(e) => {
                e.preventDefault();
                reduxDispatch(removeFlashMessage(id));
                navigate(link);
              }}
            >
              {t("button.download")}
            </Button>
          </Trans>
        </p>
      </>
    );
  }
  return {
    body: <DownloadReadyMessage doc={document} id={messageId} />,
    icon: <SvgLoader id="CloudArrowDown" className="h-6 w-6 fill-blue-600" />,
  };
};

export const getDownloadErrorMessage = (document, messageId) => {
  function DownloadErrorMessage({ doc, id }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const reduxDispatch = useDispatch();

    const { link, errorMessage } =
      DOCUMENTS_ROUTES.find(({ type }) => type === doc.fileType) ||
      DOCUMENTS_ROUTES_DEFAULT;

    const isShare = document.shareLink === true;

    const descr = isShare ? "messages.reportDocumentError" : errorMessage;

    return (
      <>
        <h4 className="text-md mb-1 font-bold leading-none text-black/90">
          {isShare ? t("messages.documentError") : t("messages.downloadError")}
        </h4>
        <p className="text-sm text-black/80">
          <Trans i18nKey={descr}>
            <strong>
              {{
                name: getDownloadDescriptionShort(doc.fileType),
              }}
            </strong>
            <Button
              customClasses="pt-0 pb-0"
              buttonType="link"
              onClick={(e) => {
                e.preventDefault();
                reduxDispatch(removeFlashMessage(id));
                navigate(link);
              }}
            />
          </Trans>
        </p>
      </>
    );
  }
  return {
    body: <DownloadErrorMessage doc={document} id={messageId} />,
    icon: <SvgLoader id="CloudArrowDown" className="h-6 w-6 fill-zinc-500" />,
  };
};

export const ledgerCreatedMessage = (ledgerId) => {
  return (
    <div>
      <Trans i18nKey="messages.ledgerHasBeenUpdated">
        <Link to={`/portfolio/ledger/${ledgerId}/summary`} />
        <Link to={`/portfolio/ledger/${ledgerId}/manage`} />
      </Trans>
    </div>
  );
};

export const ledgerAlertsMessage = (ledgerId, alertCount) => {
  return (
    <div>
      <Trans i18nKey="messages.ledgerAlertsWithLink" count={alertCount}>
        <Link to={`/portfolio/ledger/${ledgerId}/import`} />
      </Trans>
    </div>
  );
};

export const getExchangeLedgerCreatedMessage = (ledgers, importedResult) => {
  function ExchangeLedgerCreatedMessage({ ledgerList, result }) {
    // find the ledger in the ledger list
    const ledger = ledgerList.find((l) => l.id === result.ledgerId);
    const name = ledger.userLedgerName;

    return (
      <>
        <h4 className="text-md mb-1 font-bold leading-none text-black/90">
          Ledger Updated
        </h4>
        <p className="text-sm text-black/80">
          <Trans i18nKey="messages.ledgerUpdatedWithSummaryLinkOrManageLink">
            <strong>{{ name }}</strong>
            <Link
              className="text-linkBlue hover:text-linkBlueActive hover:underline"
              to={`/portfolio/ledger/${result.ledgerId}`}
            />
          </Trans>
        </p>
      </>
    );
  }

  return (
    <ExchangeLedgerCreatedMessage
      ledgerList={ledgers}
      result={importedResult}
    />
  );
};

import { BALANCE_SERVICE_ID, TELLER_SERVICE_ID } from "./ssoServiceIds";

export const { USER_BASE } = whitelabel.config;
export const { ORGS_BASE } = whitelabel.config;
export const { EXCHANGE_BASE } = whitelabel.config;
export const { ASSETS_BASE } = whitelabel.config;
export const { TELLER_BASE } = whitelabel.config;
export const { SSO_BASE } = whitelabel.config;
export const NEW_TICKET_URL_BALANCE =
  whitelabel.config.SSO_NEW_TICKET_URL + BALANCE_SERVICE_ID;
export const NEW_TICKET_URL_TELLER =
  whitelabel.config.SSO_NEW_TICKET_URL + TELLER_SERVICE_ID;

export const { MARKETING_SITE_URL } = whitelabel.config;
export const { MARKETING_SITE_NAME } = whitelabel.config;

export const { SUPPORT_SITE_URL } = whitelabel.config;

export const { TOS_URL } = whitelabel.config;
export const { PRIVACY_POLICY_URL } = whitelabel.config;
export const { CONTACT_US_URL } = whitelabel.config;

export const { API_DOCS_URL } = whitelabel.config;

export const { HEADER_IMG } = whitelabel.config;

import { STATUS } from "app.constants";

import * as filesActions from "app.actions/files";
import { reinitializePortfolio } from "app.reducers/portfolios";

// initial state
const initialState = {
  documents: undefined,
  documentsComplete: undefined,
  metadata: undefined,
  filesStatus: STATUS.UNINITIALIZED,
};

// eslint-disable-next-line default-param-last
const files = (state = initialState, action) => {
  switch (action.type) {
    case reinitializePortfolio.type: {
      return initialState;
    }
    case filesActions.FILES_REQUEST:
      return {
        ...state,
        filesStatus: action.nextStatus,
      };
    case filesActions.FILES_RECEIVE:
      return {
        ...state,
        documents: action.documents,
        documentsComplete: action.documentsComplete,
        metadata: action.metadata,
        filesStatus: action.nextStatus,
      };
    default:
      return state;
  }
};

export default files;

import { makeActionCreator } from "app.actions/helpers";
// LEDGER
export const GENERATE_FILE_BEGIN = "GENERATE_FILE_BEGIN";
export const GENERATE_FILE_REQUEST = "GENERATE_FILE_REQUEST";
export const GENERATE_FILE_RECEIVE = "GENERATE_FILE_RECEIVE";
export const GENERATE_FILE_ERROR = "GENERATE_FILE_ERROR";

export const generateFile = makeActionCreator(
  GENERATE_FILE_BEGIN,
  "fileType",
  "requestParams"
);

export const requestGenerateFile = makeActionCreator(GENERATE_FILE_REQUEST);

export const receiveGenerateFile = makeActionCreator(GENERATE_FILE_RECEIVE);

export const generateFileError = makeActionCreator(
  GENERATE_FILE_ERROR,
  "response"
);
